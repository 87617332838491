body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  /* font-family: sans-serif !important; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.spahome a.pp_next {
  display: none !important;
}

.s-body {
  padding: 70px 0;
  font: 15px/20px Arial, sans-serif;
  position: relative;
}

.s-container {
  margin: 0 auto;
  width: 400px;
  height: 200px;
  position: relative;
  perspective: 1000px;
}

.carousel {
  height: 100%;
  width: 100%;
  position: absolute;
  transform-style: preserve-3d;
  transition: transform 1s;
}

.carousel div {
  transform-style: preserve-3d;
}

.item {
  display: block;
  position: absolute;
  background: #000;
  width: 400px;
  height: 200px;
  line-height: 200px;
  font-size: 5em;
  text-align: center;
  color: #fff;
  opacity: 0.95;
  border-radius: 10px;
  transition: transform 1s;
}

.a {
  transform: rotateY(0deg) translateZ(250px);
}

.a .item {
  background: #ed1c24;
}

.b {
  transform: rotateY(60deg) translateZ(250px) rotateY(-60deg);
}

.b .item {
  background: #0072bc;
}

.c {
  transform: rotateY(120deg) translateZ(250px) rotateY(-120deg);
}

.c .item {
  background: #39b54a;
}

.d {
  transform: rotateY(180deg) translateZ(250px) rotateY(-180deg);
}

.d .item {
  background: #f26522;
}

.e {
  transform: rotateY(240deg) translateZ(250px) rotateY(-240deg);
}

.e .item {
  background: #630460;
}

.f {
  transform: rotateY(300deg) translateZ(250px) rotateY(-300deg);
}

.f .item {
  background: #8c6239;
}

.s-next,
.s-prev {
  color: #e84e0e;
  position: absolute;
  top: 55%;
  padding: 1em 2em;
  cursor: pointer;
  /* background: #CCC; */
  border-radius: 5px;
  border-top: 1px solid #fff;
  /* box-shadow: 0 5px 0 #999; */
  transition: box-shadow 0.1s, top 0.1s;
}

.s-next:hover,
.s-prev:hover {
  color: #000;
}
/* .s-next:active,
        .s-prev:active {
              top: 104px;  
             box-shadow: 0 1px 0 #999;  
        } */

.s-next {
  right: 5em;
}

.s-prev {
  left: 5em;
}

.sparliconfont {
  font-size: 35px;
}

.cp {
  cursor: pointer !important;
}

.color-black {
  color: black;
}

.owl-prev {
  display: none;
}
.owl-next {
  display: none;
}

.slider-left div,
.slider-right div {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: crimson;
  border: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-left div i,
.slider-right div i {
  color: white !important;
}

input[type="text"],
select,
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

input[type="submit"] {
  background-color: #04aa6d;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type="submit"]:hover {
  background-color: #45a049;
}

::placeholder {
  color: rgb(36, 34, 34) !important;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgb(36, 34, 34) !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgb(36, 34, 34) !important;
}

@media screen and (min-width: 992px) {
  .mob_nav {
    position: absolute;
    z-index: 100;
    color: white;
    background-color: #04101b;
    width: 100%;
    height: 100vh;
    top: 150px;
  }
}

@media screen and (max-width: 991px) {
  .mob_nav {
    position: absolute;
    z-index: 100;
    color: white;
    background-color: #04101b;
    width: 100%;
    min-height: 100vh;
    top: 100px;
  }
}

.nav-item {
  font-size: 1.2em;
  font-weight: 400;
  color: white;
  border-bottom: 1px solid rgba(211, 211, 211, 0.233);
  padding-bottom: 1rem;
}

.sub-cat {
  /* display: none; */
  margin-left: 1.5rem;
  font-size: 0.7em;
  margin-top: 1rem;
}

.bg_nav_item {
  color: white !important;
}
.bg_nav_item:hover {
  color: #e84e0e !important;
}

.home_sub-title {
  font-size: 1.99em;
  color: white;
  font-weight: 700;
  margin-top: 1rem;
  letter-spacing: 2px;
}

@media (max-width: 767px) {
  .home_sub-title {
    font-size: 1em;
  }
}

.mob-nav-btn {
  display: none;
}

@media (max-width: 1199px) {
  .mob-nav-btn {
    display: initial !important;
  }
}

.img-aspect {
  aspect-ratio: 4/3;
  /* margin: auto; */
}

.home__trending-carousel-navbtn-wrapper {
  position: absolute;
  background-color: #e84f0e;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  top: 50%;
  cursor: pointer;
}

.home__trending-carousel-navbtn-wrapper span {
  font-size: 1.5em !important;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.home__trending-carousel-btn-left {
  left: 0;
}

.home__trending-carousel-btn-right {
  right: 0;
}

.animate-gallery-text {
  animation-name: galtext;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  /* text-shadow: 1px 1px 10px #fff, 1px 1px 10px #ccc; */
  letter-spacing: 1px;
}

@keyframes galtext {
  0% {
    text-shadow: 1px 1px 2px transparent, 1px 1px 10px transparent;
  }
  50% {
    text-shadow: 1px 1px 2px #fff, 1px 1px 10px #fff;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.hide-scrollbar {
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none !important;
}

.home-banner-wrapper .ant-modal-content {
  background-color: transparent !important;
}
